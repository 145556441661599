<template>
  <form class="relative">
    <div
      v-show="isBusy"
      class="absolute inset-0 bg-faint-white flex items-center justify-center z-20"
    >
      <spinner :color="'text-black'" :size="10" />
    </div>
    <is-authorized :permissions="['manage:leaveAll']">
      <div class="mb-2">
        <label class="uppercase font-semibold text-sm"
          >Staff
          <span class="text-red-500">*</span>
        </label>

        <div
          class="fixed inset-0 overflow-hidden z-10"
          v-if="staffSelectOpen"
          @click="staffSelectOpen = false"
        ></div>
        <div class="relative mx-3">
          <div class="relative -mx-3">
            <input
              readonly
              @click="openSearchStaff"
              v-model="staffName"
              class="w-full bge-input py-2 pl-4 pr-10 rounded cursor-pointer"
              type="text"
            />
            <button
              v-if="staffName"
              name="Clear Supplier"
              @click="clearStaff"
              class="m-1 z-10 text-red-500 hover:bg-red-200 rounded-full absolute inset-y-0 right-0 flex items-center px-2 transition-colors duration-150 ease-in-out"
            >
              <svg
                class="stroke-current h-5 w-5"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <path d="M18 6L6 18M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div
            v-if="staffSelectOpen"
            class="z-10 absolute w-full max-h-5vh bg-white shadow-lg rounded border border-gray-300 top-0 mt-5 p-2"
          >
            <div class="relative flex flex-col">
              <input
                ref="tbStaffSearch"
                type="text"
                v-model="staffSearchTerm"
                @keydown="searchStaff"
                class="bge-input bge-input-spacing rounded"
                placeholder="Staff name (enter 3 letters or more to search)"
              />
              <div class="flex flex-col overflow-y-scroll bg-white mt-2">
                <template v-for="result in staffResults">
                  <button
                    type="button"
                    :key="result.staff_id"
                    @click="selectStaff(result)"
                    class="hover:bg-orange-200 flex items-center py-2 transition duration-100 focus:bg-orange-200 text-left px-2"
                  >
                    <span class="ml-1">
                      {{ result.first_name }}
                    </span>
                  </button>
                </template>
                <p class="mx-auto py-4 px-8" v-if="staffResults == ''">
                  No staff match your search
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </is-authorized>
    <div class="w-full mb-2">
      <label class="uppercase font-semibold text-sm">
        Leave type
        <span class="text-red-500">*</span>
      </label>
      <div class="w-full relative">
        <select class="bge-input bge-select rounded" v-model="leave.leave_type">
          <option value="Absent">Absent</option>
          <option value="Annual Leave">Annual Leave</option>
          <option value="Compassionate Leave">Compassionate Leave</option>
          <option value="Paternity Leave">Paternity Leave</option>
          <option value="Statutory Maternity Leave"
            >Statutory Maternity Leave</option
          >
          <option value="Statutory Sick Leave">Statutory Sick Leave</option>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="mb-2">
      <label class="uppercase font-semibold text-sm">From</label>
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="leave.start_date"
        format="dd/MM/yyyy"
        @closed="toDateClosed"
      ></datepicker>
    </div>
    <div class="mb-2">
      <label class="uppercase font-semibold text-sm">To</label>
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="leave.end_date"
        format="dd/MM/yyyy"
        @closed="toDateClosed"
      ></datepicker>
    </div>
    <div class="w-full mb-2">
      <label class="uppercase font-semibold text-sm">Reason for leave</label>
      <input
        v-model="leave.purpose"
        class="bge-input bge-input-spacing w-full rounded"
      />
    </div>
    <div class="w-full mb-2">
      <label class="uppercase font-semibold text-sm">Days</label>
      <input
        disabled
        v-model="leave.days"
        class="bge-input bge-input-spacing w-full rounded"
      />
    </div>

    <div class="relative flex justify-end mt-5 space-x-4">
      <is-authorized :permissions="['manage:leaveAll']">
        <button
          v-if="staff.isEdit"
          @click="approveLeave"
          type="button"
          class="flex items-center text-secondary py-1 px-3 rounded border bg-green-200  hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-100"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path d="M0 0h24v24H0z" stroke="none" />
            <path d="m5 12 5 5L20 7" />
          </svg>
          <span>Approve</span>
        </button>
      </is-authorized>
      <is-authorized :permissions="['manage:leaveAll']">
        <button
          v-if="staff.isEdit"
          @click="rejectLeave"
          type="button"
          class="flex items-center  text-secondary py-1 px-3 rounded border bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-100"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path d="M18 6 6 18M6 6l12 12" />
          </svg>
          <span>Reject</span>
        </button>
      </is-authorized>
      <button
        @click="createOrUpdateLeave"
        type="button"
        class="flex items-center bg-blue-200 hover:bg-blue-300 text-secondary py-1 px-3 rounded border border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>{{ staff.isEdit ? "Update" : "Save" }}</span>
      </button>
    </div>

    <div class="flex flex-col justify-center pt-4 ">
      <div class="flex items-center mb-5">
        <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
          Available Balances - {{ new Date().getFullYear().toString() }}
        </h3>
        <hr class="flex-1 border-primary ml-4" />
      </div>
      <div
        class="w-full max-w-2xl mx-auto bg-white  rounded-sm border border-gray-200"
      >
        <div>
          <vue-good-table
            :columns="columns"
            :rows="availableLeaves"
            :sort-options="{ enabled: false }"
            styleClass="vgt-table condensed"
            mode="remote"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
const Spinner = () => import("@/components/Spinner.vue");
const Notification = () => import("@/components/Notification.vue");
const Toggle = () => import("@/components/Toggle.vue");
const Datepicker = () => import("vuejs-datepicker");
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");
import { BANKHOLIDAYS } from "@/utils/constants";

export default {
  name: "CreateLeave",
  components: {
    Spinner,
    Toggle,
    Datepicker,
    IsAuthorized,
    Notification
  },
  props: {
    staff: {
      type: Object
    },
    managingOwnLeave: Boolean
  },
  data() {
    return {
      isBusy: false,
      leave: {},
      availableLeaves: [],
      staffName: "",
      staffSearchTerm: "",
      staffResults: [],
      staffSelectOpen: false,

      columns: [
        {
          label: "Leave Type",
          field: "leave_type"
        },
        {
          label: "Total Days",
          field: "total_days",
          type: "number"
        },
        {
          label: "Available Balance Days ",
          field: "available_days",
          type: "number"
        }
      ]
    };
  },
  mounted() {
    const year = new Date().getFullYear().toString();
    if (this.staff && this.staff.isEdit) {
      this.selectStaff(this.staff);
      this.availableLeaves = this.staff.leave_balance.filter(bal => {
        return bal.year == year;
      });
    } else if (this.staff) {
      if (this.managingOwnLeave) {
        this.availableLeaves = this.staff.leave_balance.filter(bal => {
          return bal.year == year;
        });
        this.leave.staff_id = this.staff.staff_id;
      }
    }
  },
  computed: {
    canSubmitForm() {
      return (
        this.leave.leave_id && this.leave.start_date && this.leave.end_date
      );
    }
  },
  methods: {
    calculateLeaveDays: function() {
      let bankHolidays = BANKHOLIDAYS;

      var dates = bankHolidays.map(function(i) {
        return i.date;
      });

      this.$moment.updateLocale("us", {
        holidays: dates,
        holidayFormat: "YYYY-MM-DD",
        workingWeekdays: [1, 2, 3, 4, 5]
      });

      const start = this.$moment(this.leave.start_date).format("DD/MM/YYYY");
      const end = this.$moment(this.leave.end_date).format("DD/MM/YYYY");

      let isStartBusinessDay = this.$moment(
        start,
        "DD-MM-YYYY"
      ).isBusinessDay();
      let isEndBusinessDay = this.$moment(end, "DD-MM-YYYY").isBusinessDay();
      var diff = this.$moment(end, "DD-MM-YYYY").businessDiff(
        this.$moment(start, "DD-MM-YYYY"),
        true
      );

      if (isStartBusinessDay || isEndBusinessDay) {
        diff;
      }
      return diff;
    },
    toDateClosed: async function() {
      if (this.leave.start_date && this.leave.end_date) {
        // const start = this.$moment(this.leave.start_date);
        // const end = this.$moment(this.leave.end_date);
        // this.leave.days = end.diff(start, "days") + 1;
        this.leave.days = this.calculateLeaveDays();
      }
    },
    openSearchStaff: function() {
      this.staffSearchTerm = "";
      this.staffResults = [];
      this.staffSelectOpen = true;
      this.$nextTick(function() {
        this.$refs.tbStaffSearch.focus();
      });
    },
    searchStaff: _.debounce(async function() {
      this.staffResults = [];
      if (this.staffSearchTerm != "" && this.staffSearchTerm.length >= 3) {
        let results = await this.HolidayService.searchStaff(
          this.staffSearchTerm,
          { select: "staff_id,first_name,last_name,leave_balance" }
        );
        this.staffResults = results.data;
        this.staffResults.leaves = {};
      }
    }, 500),
    selectStaff: function(staff) {
      const year = new Date().getFullYear().toString();
      if (staff.staff_id) {
        this.leave = staff.leaves ? staff.leaves : {};
        this.leave.staff_id = staff.staff_id;
        this.availableLeaves = staff.leave_balance.filter(bal => {
          return bal.year == year;
        });
        this.staffName = staff.first_name;
        this.$forceUpdate();
      }
      this.staffSelectOpen = false;
    },
    clearStaff: function() {
      this.leave.staff_id = "";
      this.staffName = "";
      this.leave = {};
      this.availableLeaves = {};
      this.$forceUpdate();
    },
    approveLeave: async function() {
      this.leave.status = "approved";
      await this.createOrUpdateLeave();
    },
    rejectLeave: async function() {
      this.leave.status = "rejected";
      await this.createOrUpdateLeave();
    },
    async createOrUpdateLeave() {
      try {
        console.log("Original start_date:", this.leave.start_date);
        console.log("Original end_date:", this.leave.end_date);

        // Validate input fields
        const validationMessage = this.validateLeaveInput();
        if (validationMessage) {
          return this.showNotification("warning", "Error", validationMessage);
        }

        // Convert dates to Unix timestamps
        if (!this.$moment(this.leave.start_date).isValid()) {
          throw new Error("Invalid start date");
        }
        if (!this.$moment(this.leave.end_date).isValid()) {
          throw new Error("Invalid end date");
        }
        if (typeof this.leave.start_date !== "number") {
          this.leave.start_date = this.$moment(this.leave.start_date).unix();
        }
        if (typeof this.leave.end_date !== "number") {
          this.leave.end_date = this.$moment(this.leave.end_date).unix();
        }

        // API Call
        if (!this.leave.leave_id) {
          this.leave.status = "pending";
          await this.HolidayService.createStaffLeave(
            this.leave.staff_id,
            this.leave
          );
        } else {
          await this.HolidayService.updateLeave(
            this.leave.staff_id,
            this.leave.leave_id,
            this.leave
          );
        }

        this.$emit("complete");
        this.showNotification(
          "success",
          "Success",
          "Leave has been created successfully."
        );
      } catch (error) {
        console.error("Error while processing leave:", error);
        this.showNotification(
          "danger",
          "Error",
          "There's been an error whilst creating Leave."
        );
      } finally {
        this.isBusy = false;
      }
    },

    validateLeaveInput() {
      if (!this.leave.staff_id) return "Please select staff";
      if (!this.leave.leave_type) return "Please choose leave type";
      if (!this.leave.start_date) return "Please choose start date";
      if (!this.leave.end_date) return "Please choose end date";
      if (this.leave.start_date > this.leave.end_date)
        return "From date should be greater than To date";

      if (!this.managingOwnLeave) {
        const totalBookedDays = this.calculateLeaveDays();

        for (const item of this.availableLeaves) {
          if (
            item.leave_type === this.leave.leave_type &&
            (item.balance_days < totalBookedDays ||
              item.total_days < totalBookedDays)
          ) {
            return "Booking days should be match with available balance days";
          }
        }
      }
      return null;
    },

    showNotification(type, title, message) {
      return this.$breadstick.notify(
        ({ h, onClose }) => {
          return h(
            Notification,
            {
              props: {
                type: type,
                title: title,
                close: onClose
              }
            },
            message
          );
        },
        {
          position: "top-right"
        }
      );
    }
  }
};
</script>
